import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ClasesService {

  constructor(private firestore: AngularFirestore) { }

  getClases() {
    return this.firestore.collection('clases').snapshotChanges();
  }
  getClasesNow() {
    return this.firestore.collection('clases').get();
  }

  getClaseById(uid) {
    return this.firestore.collection('clases').doc(uid).snapshotChanges();
  }
  getClaseByIdNow(uid) {
    return this.firestore.collection('clases').doc(uid).get();
  }
  getUserClass(uid) {
    return this.firestore.collection('clases', ref => ref.where('usuarios', 'array-contains' , uid)).snapshotChanges();
  }
  getUserClassNow(uid) {
    return this.firestore.collection('clases', ref => ref.where('usuarios', 'array-contains' , uid)).get();
  }
  getAdminClassNow(uid) {
    return this.firestore.collection('clases', ref => ref.where('administradores', 'array-contains' , uid)).get();
  }

  createdClase(clase) {
    return this.firestore.collection('clases').add(clase);
  }

  editClase(uid, clase) {
    return this.firestore.collection('clases').doc(uid).update(clase);
  }

  getClaseByCursoid(uid) {
    return this.firestore.collection('clases', ref => ref.where('cursos', 'array-contains' , uid)).snapshotChanges();
  }
  getClaseByCursoidNow(uid) {
    return this.firestore.collection('clases', ref => ref.where('cursos', 'array-contains' , uid)).get();
  }

  getClaseByColegio(uid) {
    return this.firestore.collection('clases', ref => ref.where('colegio', '==' , uid)).snapshotChanges();
  }
  getClaseByColegioNow(uid) {
    return this.firestore.collection('clases', ref => ref.where('colegio', '==' , uid)).get();
  }

  DeleteClass(uid){
    return this.firestore.collection('clases').doc(uid).delete();
  }

  async getClassNames(classIds: string[]) {
    const classNames: string[] = [];
    for (const id of classIds) {
      const classDoc: any = await this.firestore.collection('clases').doc(id).get().toPromise();
      if (classDoc.exists) {
        const className = classDoc.data().nombre;
        classNames.push(className);
      }
    }
    return classNames;
  }

}
