import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ColegiosService } from '../services/colegios.service';
import { CursosService } from '../services/cursos.service';
import { ClasesService } from '../services/clases.service';
import { UserService } from '../services/user.service';
import { LicenciaService } from '../services/licencia.service';
import { AuthenticationService } from '../services/authentication.service';
import { auth } from 'firebase';
import { Router } from '@angular/router';
import { ExcelService } from '../services/excel.service';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-test123',
  templateUrl: './test123.component.html',
  styleUrls: ['./test123.component.css'],
})
export class Test123Component implements OnInit {
  correos = [];

  cursos: any = [];
  clases: any = [];
  numerofinal: number = 0;
  numeroinicial: number = 0;
  codigos: any = [];
  codigosNoEstan: any = [];
  uids = [];

  licencias: any = [
    'MSHRQ-10000-69886-66293',
    'MSHRQ-10001-59977-89671',
    'MSHRQ-10002-12780-11052',
    'MSHRQ-10003-50689-75291',
    'MSHRQ-10004-27135-39032',
    'MSHRQ-10005-22183-87046',
    'MSHRQ-10006-10622-42967',
    'MSHRQ-10007-49190-90763',
    'MSHRQ-10008-61827-11655',
    'MSHRQ-10009-69344-71348',
    'MSHRQ-10010-54277-94562',
    'MSHRQ-10011-28915-75055',
    'MSHRQ-10012-16852-15199',
    'MSHRQ-10013-93053-92219',
    'MSHRQ-10014-42415-25701',
    'MSHRQ-10015-73981-20625',
    'MSHRQ-10016-77024-14551',
    'MSHRQ-10017-84160-55442',
    'MSHRQ-10018-68269-53529',
    'MSHRQ-10019-99051-42310',
    'MSHRQ-10020-57521-16188',
    'MSHRQ-10021-29252-46717',
    'MSHRQ-10022-64154-98023',
    'MSHRQ-10023-72640-80632',
    'MSHRQ-10024-50802-10928',
    'MSHRQ-10025-75660-59090',
    'MSHRQ-10026-78094-89241',
    'MSHRQ-10027-40669-76293',
    'MSHRQ-10028-17515-77540',
    'MSHRQ-10029-68725-44305',
  ];

  codigosUsuarios = [];

  usuariosUid: any = [];
  usuarios: any = [];
  listadoUsuariosColegia: any = [];
  datos: any[] = [];
  usuariosValidos = [];
  usuariosNoValidos = [];
  viajosEmail: any = [];
  actualesEmail: any = [];
  mostrar = false;
  usuariosAntiguos = [];
  constructor(
    private http: HttpClient,
    private colegiosService: ColegiosService,
    private cursosService: CursosService,
    private clasesService: ClasesService,
    private usuariosService: UserService,
    private licenciasService: LicenciaService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private excelService: ExcelService
  ) {}

  ngOnInit(): void {

  }
  async recogerDatosAlumnos() {
    try {
      const colegio = 'qKQCtDaQ5GRP8wsJVVsi';
      const snapshot = await this.usuariosService.getUserByColegioAndRole(colegio).toPromise();
      if (snapshot.empty) {
        console.log('No se encontraron usuarios.');
        return;
      }
      const users = await Promise.all(snapshot.docs.map(async doc => {
        const userData: any = doc.data();
        // console.log('User Data:', userData);  // Verificar datos obtenidos
        if (userData.clases) {
          // userData.classNames = await this.clasesService.getClassNames(userData.clases);
          const classNames = await this.clasesService.getClassNames(userData.clases);
          userData.classNames = classNames.join(', '); // Convertir array a string separado por comas
        
        }
        if (userData.fechanac && userData.fechanac.toDate) {
          userData.fechanac = userData.fechanac.toDate();
      }
        return userData;
      }));
      if (users.length === 0) {
        console.log('No hay usuarios para exportar.');
        return;
      }
      console.log('Usuarios obtenidos:', users);  // Verificar datos finales
      this.excelService.exportAsExcelFile(users, 'Users');
    } catch (error) {
      console.error('Error exporting users:', error);
    }
  }



  cambiarFinal(num) {
    this.numerofinal = num;
  }

  getUsers() {
    this.uids.forEach((uid) => {
      this.usuariosService.getUserById(uid).subscribe((user) => {
        if (user.length > 0) {
          var usuario = user[0].payload.doc.data();
          this.usuarios.push(usuario);
        }
      });
    });
  }

  getUsersByCodigo() {
    this.usuarios = [];
    this.codigosUsuarios.forEach((codigo) => {
      this.usuariosService.getUserCodigo(codigo).subscribe((user) => {
        if (user.size > 0) {
          var usuario: any = user.docs[0].data();
          console.log('usuario con código: ' + codigo, usuario.uid);
          this.usuarios.push(usuario.uid);
        }
      });
    });
  }

  // getUsuariosPorLista() {
  //   let emails = [

  //   ];
  //   // let emailsBuenos = [];
  //   emails.forEach((email) => {
  //     let emailBueno =""
  //     if (!email.includes('@')) {
  //       emailBueno = email + '@ais.colegia.org'
  //     } else {
  //       emailBueno = email
  //     }
  //     this.usuariosService.getUserByEmail(emailBueno).subscribe((usuarios:any) => {
  //       let usuario = usuarios.docs[0].data();
  //       console.log("EMAIL:" + usuario.email)
  //       console.log(usuario.uid)
  //       this.uids.push(usuario.uid)
  //     });
  //   });

  //   // console.log(emailsBuenos);

  // }

  getUsuariosCole() {
    const cole = 'qKQCtDaQ5GRP8wsJVVsi';
    this.usuariosService.getUserColegio(cole).subscribe((usuarios) => {
      console.log('val', usuarios.size);
      usuarios.forEach((usuario: any) => {
        // console.log(usuario.data().uid)
        if (
          usuario.data().usuario.includes('@academica') ||
          usuario.data().usuario.includes('laiatech') ||
          usuario.data().usuario.includes('gofacing') ||
          usuario.data().usuario.includes('thecampus')
        ) {
          console.log(usuario.data().usuario);
        } else {
          this.uids.push(usuario.data().uid);
        }
        // var valor = {
        //   codigo: usuario.data().codigo,
        //   usuario: usuario.data().usuario,
        //   nombre: usuario.data().nombres,
        //   apellidos: usuario.data().apellidos,
        //   emailpersonal: usuario.data().email,
        //   tipo:usuario.data().rol,
        //   colegio: usuario.data().cursos[0].nombre,
        // };
        // this.listadoUsuariosColegia.push(valor);
        // console.log("valor",valor)
      });
      console.log(this.uids.length);
    });
  }

  //files xlsx
  fileChangeEvent(event: any): void {
    this.datos = [];
    var datosXLSX = null;
    datosXLSX = this.excelService.importAsExcelFile(event);
    setTimeout(() => {
      let onload = this.onload(datosXLSX.result);
      this.datos = onload;
      console.log('DATOS', this.datos);
    }, 2000);
  }

  onload(e) {
    const binarystr: string = e;
    const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

    /* selected the first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    return data;
  }

  comprobacion() {
    console.log(this.datos.length);
    console.log(this.datos);
    this.datos.forEach((dato) => {});
  }

  guardar() {
    this.datos.forEach((dato) => {
      console.log(dato);
      if (dato.email) {
        this.actualesEmail.push(dato.email);
      }
      if (dato.email_viejos) {
        this.viajosEmail.push(dato.email_viejos);
      }
    });
  }
  comprobar() {
    console.log(this.actualesEmail);
    console.log('2--------------------');
    console.log(this.viajosEmail);
    console.log('--------------------');
    this.actualesEmail.forEach((element) => {
      if (this.viajosEmail.includes(element)) {
        this.usuariosAntiguos.push(element);
        console.log(element);
      }
    });
  }

  verViejos() {
    this.mostrar = true;
    console.log(this.usuariosAntiguos);
  }

  comprobarSalasConUsuarios() {
    this.usuariosValidos = [];
    this.usuariosNoValidos = [];
    this.datos.forEach((dato) => {
      this.usuariosService.getUserByUsuario(dato.name).subscribe((usuario) => {
        if (!usuario.empty) {
          let usuarioId = usuario.docs[0].id;
          console.log(
            'Existe el usuario ' + dato.name + ' con ID: ' + usuarioId
          );
          this.usuariosValidos.push(dato.name);
        } else {
          this.usuariosNoValidos.unshift(dato.name);
        }
      });
    });
    setTimeout(() => {
      console.log(this.usuariosNoValidos.length);
      console.log(this.usuariosNoValidos);
    }, 30000);
  }

  crearSalas() {
    var usuarios = [
      'agscole@gmail.com',
      'k.simpson@colegiolosnaranjos.com',
      'autoenglish@gmail.com',
      'theresa.togneri@oviedo.escuelateresiana.com',
      'aberazaluce@larraonaclaret.org',
      'juliorico.jesusmaestro@gmail.com',
      'dual@colegiovalledelmiro.es',
      'ldiazs@abatoliba.edu',
      'cfrancog@lasallemaravillas.com',
      'asuncion.nicolas@colegioceu.es',
      'olavi.medina@laspalmas.escuelateresiana.com',
      'mgaricano@aveteaching.com',
      'director@colegiosangabriel.es',
      'ana.gomez@eacomplutense.es',
      'mariacarmen.miguelbancora@ceu.es',
      'raqueljalle@cristoreyescolapios.org',
      'bacharelatodual@colegioesclavas.org',
      'jaimebravomendoza@gmail.com',
      'jjohnsoninspain@gmail.com',
      'l.felices@stlouisfrancais.com',
      'juan.lopez@colegiomontesclaros.es',
      'administracionyservicios@colegioarula.com',
      'mcosta@colegioceu.es',
      'dualprogram@colegiochesterton.es',
      'begona.gainza@donostia.teresiareskola.com',
      'janeperez1@hotmail.es',
      'dual@colegiolaasuncion.com',
      'mcjduch@yahoo.es',
      'mercedesarriaza@divinomaestromadrid.com',
      'mariana.cabrero@colegiopenalar.es',
      'leticia.garcia@colegiosangabriel.es',
      'arantxamg74@outlook.com',
      'a.noriega@colegioarula.com',
      'dual@cepiscopal.org',
      'colegio@claretsegovia.es',
      'bachdual.lasallesantiago@gmail.com',
      'coordinador.dual@claretmadrid.es',
      'ana.llinares@calahorra.escuelateresiana.com',
      'jmbarrios@sanpedroapostol.eu',
      'bachillerato2aristos@gmail.com',
      'carla.liebo@colegiolossauces.com',
      'bachilleratodual@colegioaltair.com',
      'miguel.bueno@safajj.corazonistas.org',
      'ogarcia@stainternational.edu.es',
      'carmen.duran@gaudem.es',
      'coorbachdualclaretsg@gmail.com',
      'diploma.dual@sacora.es',
      's.gonzalez@santaisabel.es',
      'p.cermeno@gsd.coop',
      'r.sardinero@gsd.coop',
      'xavirodriguez@escolesbetlem.com',
      'carmengaston@codimadrid.com',
      'mpons@padredamiansscc.net',
      'lmoreno@palcam.cat',
      'gloria.english.joyfe@gmail.com',
      'bachilleratodual@colegioalmedina.com',
      'paternainternationaloffice@lasallevp.es',
      'btodual@lasallecordoba.es',
      'jefaturaeso@humanitastorrejon.com',
      'batxilleratdual@betania-patmos.org',
      'albert.perez@franciscanespoblenou.org',
      'michael.murphy@edificiseminari.com',
      'dualdiploma@santandreu.manyanet.org',
      'academica@laginesta.com',
      'jgoma@lasalle.cat',
      'laura.castane@salesians.cat',
      'adela.gomez@colegio-alameda.com',
      'fatima.casablanca@colegio-arcangel.com',
      'marifecuesta@campolara0365.educamos.com',
      'jcaballero@madredediosbilbao.es',
      'calbalat@colegiomantellate.es',
      'dsanchezdi@maristassalamanca.org',
      'liceocastilladiplomadual@gmail.com',
      'bachilleratodual@colegiomiramadrid.es',
      'cristina.rodriguez@colegiosantotomas.es',
      'jmembrado@centroscatolicos.org',
      'mmartinezm@fefcoll.org',
      'annaacademiccoach@villena.cat',
      'monica.fernandez@sarria.epiaedu.cat',
      'dolors.ventura@terrassa.epiaedu.cat',
      'lourdesorplp@gmail.com',
      'jculsan@lasalle.cat',
      'cferrer@mjosefacampos.com',
      'plorenzo@lasalleeibar.eus',
      'carmenbusto@paulafrassinetti.es',
      'ibetancor@claretlaspalmas.digital',
      'rafaelpleguezuelos@agustinosgranada.es',
      'bachilleratodual@virgendemirasierra.eu',
      'ngomez@fedac.cat',
      'marta.rovira@carmelites.cat',
      'clara.pla@tarragona.escolateresiana.com',
      'jen@agustinosgranada.es',
      'bachilleratodual@colegiomalvar.com',
      'jmserrano@colegiosantamonica.es',
      'leire.salas@fefcoll.org',
      'susanafernandezsantamaria@gmail.com',
      'administracion@colegiodecroly.com',
      'mzulueta@aveteaching.com',
      'victoria.segura@santasusana.corazonistas.org',
      'cristina.sanchez@orvalle.es',
      'lali.arbues@saintnicholas.cat',
      'karine.adnot@fesofiabarat.es',
      'eburgos@jesuitinasmariavirgen.es',
      'vicky@saldanet.com',
      'aganuza@dehonianospuente.es',
      'rjorge_dpl@edu.anamogas.org',
      'kjlewis22@yahoo.es',
      'rubens@santamariaikastetxea.com',
      'english.dept@csamadrid.org',
      'igarcia@agustinoszaragoza.com',
      'mjose.gonzalezmo@salesianas.org',
      'mclavel@colegioguadalaviar.es',
      'desamparados.mora@elvedat.escolateresiana.com',
      'paola.romaguera@colegiohelios.com',
      'bachidual@cbcmadrid.es',
      'cdemigu@gmail.com',
      'juanjo.martinez@salesianos.edu',
      'dleal@colegiolosangeles.net',
      'batdual@iconcepcionistas.es',
      'tutor.btxdual@stnicolau.com',
      'epatton@escolameritxell.org',
      'gomez@pereziborra.com',
      'assumpta.claramunt@igualada.epiaedu.cat',
      'bclemente@emdn.cat',
      'eparry@maristasourense.com',
      'mary.finbow@colegiocastroverde.org',
      'pmartin@durangojesuitak.org',
      'batxidual@elkarhezi.com',
      'jufrabale@gmail.com',
      'vmariec@hotmail.es',
      'secretaria@alfa-omega.org',
      'monfele@hotmail.com',
      'jmelcuco@jesus-maria.net',
      'rafael_hidalgo86@hotmail.com',
      'marialuisaclavijo@agustiniano.com',
      'ireneguzman@safa.cat',
      'olga.carceller@bellvitge.escolateresiana.com',
      'jmribas@cemporda.org',
      'lunaescribanolopez@gmail.com',
      'dianapeirat@gmail.com',
      'm.blanco@pinoalella.com',
      'rociolmoyano@gmail.com',
      'christinedelmelle@lopedevega.es',
      'fatimafec@csanjose.org',
      'mercedes.ponce@corazoninmaculado.es',
      'angelamena@smnarancoav.org',
      'm.rey@gsd.coop',
      'Montse.raventos@igualada.epiaedu.cat',
      'batdual@iconcepcionistas.es',
      'teresa.nuno@salesianas.org',
      'jsole@sagratcorelvendrell.cat',
      'lena.mo@nuevoequipo.es',
      'santandreu@pereziborra.com',
      'gonzalo@maristas-oviedo.org',
      'marga@centrogalen.com',
      'immaculada.dual@maristes.cat',
      'jlsanjuan@corazonistasvitoria.com',
      'jmrodrigo@corazonistasvitoria.com',
      'ruben@colegioamigo.com',
      'amparo.castillo@guadalimar-altocastillo.com',
      'juanfrancisco.vilchez@guadalimar-altocastillo.com',
      'xplazas@la-farga.org',
      'ntapias@la-vall.org',
      'aperarnau@la-vall.org',
      'ANA.PORRO@GRUPRAMAR.COM',
      'dubyaldana@reginahorta.com',
      'amarin@ekintza.eus',
      'carleslorente@ginebro.cat',
      'asier.salazar@sanviator.eus',
      'prati@escola-arrels.cat',
      'iazcarate@avetroig.com',
      'batx_america@escola-proa.cat',
      'spaz@fedac.cat',
      'estefaniacabarcosblanco@gmail.com',
      'cenglish@colegiointernacionaldevalladolid.com',
      'emmawalsh@colegiocedes.com',
      'fmartin@andel.es',
      'ezequiel-garcia@salesianostrinidad.net',
      'lchavales_scf@edu.anamogas.org',
      'caballerodual@gmail.com',
      'cmtzuzendaritza@karmengoama.eus',
      'anaisabel.ordonez@xtrafec.com',
      'bdual@elcarmelo.net',
      'pherrero@cristoreylasrozas.org',
      'beth.boyle@ayalde.com',
      'alejandro.pacheco@sierrablanca-elromeral.com',
      'luismiguelcastro@alfonsoxiielescorialo365.educamos.com',
      'regina.echevarria@colegiourdaneta.es',
      'inakioa@berrio-otxoaikastetxea.com',
      'maitegazquez@vedrunapalamos.org',
      'elizabeth.lynch@claretbarcelona.cat',
      'lucila.larraga@arangoya.net',
      'batxdual@bonsalvador.com',
      'rmontero@maristes.com',
      'eduard.beltran@salesians.cat',
      'marionassmann@escorialvic.cat',
      'nataliasevilla@escorialvic.cat',
      'diego.delabarrera@grazalema-guadalete.com',
      'marisol.sanchez@eaymersscc.com',
      'lzubeldia@lasalleberrozpe.eus',
      'imarch@maristes.com',
      'alvaroleis_dpou@edu.anamogas.org',
      'Carlos.Moreno@agorainternationalandorra.com',
      'raquel.monteverde@tortosa.escolateresiana.com',
      'monipeny1@gmail.com',
      'ivanguell@vedrunagirona.org',
      'kennethpages@vedrunagirona.org',
      'maricarmen.lapresen@gmail.com',
      'amadeo.ballester@scientiadenia.com',
      'maria.sarrio@salesianas.org',
      'annadelgrande@pratsdelacarrera.org',
      'batxilleratdual@vedrunapuigcerda.org',
      'dual@horta.lasalle.cat',
      'evarodriguez@col-legivirolai.net',
      'monica.castanyer@escolagem.cat',
      'pcentelles@safasabadell.com',
      'macarena.sagradocorazon@gmail.com',
      'englishdepartment@munabe.com',
      'asasasa@gmail.com',
      'lore@antonianokide.com',
      'leticiarodrigo@lasallevp.es',
      'delariva.maria@hotmail.com',
      'bachilleratodual@safasi.com',
      'mmulet@aveteaching.com',
      'oabellon@cldh.es',
      'bachilleratodual@instituciomallorca.org',
    ];

    // let usuariosTest = [
    //   'sergiomartin@escolapiosemaus.org',
    //   'direccion@alminar.es',
    // ];
    for (let index = 0; index < usuarios.length; index++) {
      const email = usuarios[index];
      console.log("index", index)
      this.usuariosService.getUserByEmail(email).subscribe((usuarios:any)=>{
        if (usuarios.docs.length > 0) {
          let datos = usuarios.docs[0].data();
          console.log("datos: ", datos)
          this.crearSala(datos)
        }
      });
    }
  }

  crearSala(datos) {
    var dataTrans = {
      codigo: datos.codigo,
      nombre: datos.nombres,
      apellidos: datos.apellidos,
      cole: datos.cursos[0].nombre,
      email: datos.email,
    };
    var jsonbody = JSON.stringify(dataTrans);

    const url =
      'https://us-central1-the-campus-prod.cloudfunctions.net/app/crearSalaEnPexip';
    fetch(url, {
      method: 'POST',
      body: jsonbody,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log("crada sala: ", datos.email)
      });
  }

  salasEliminar() {
    // this.usuariosNoValidos=[
    // ]
    // this.borrarSalasPexip()
  }

  async borrarSalasPexip() {
    for (const [index, sala] of this.usuariosNoValidos.entries()) {
      console.log(index);
      console.log(sala);
      try {
        const response = await fetch(
          'https://us-central1-the-campus-prod.cloudfunctions.net/deleteSalaPexip?salaname=' +
            sala,
          {
            method: 'GET', // o 'DELETE' si es para borrar
          }
        );
        const data = await response.json();
        console.log(index);
        console.log('delete: ', sala);
      } catch (error) {
        console.log('ERROR------------', error);
      }
    }
  }

  descargarUsuariosColegia() {
    console.log('usuarios', this.listadoUsuariosColegia.length);
    this.excelService.exportAsExcelFile(
      this.listadoUsuariosColegia,
      'usuarios_thecampus'
    );
  }

  // borrarPorCodigo(){
  //   this.usuarios.forEach((uid,index)=>{
  //     console.log(index)
  //     console.log(uid)
  //     this.http
  //     .get<any>(
  //       'https://us-central1-the-campus-prod.cloudfunctions.net/delUser',
  //       { params: new HttpParams().set('uid', uid) }
  //     )
  //     .subscribe((data) => {
  //       console.log(index)
  //       console.log("delete: ", data)
  //     });
  //   });
  // }

  deleteUsers() {
    this.uids.forEach((uid, index) => {
      this.http
        .get<any>(
          'https://us-central1-the-campus-prod.cloudfunctions.net/delUser',
          { params: new HttpParams().set('uid', uid) }
        )
        .subscribe((data) => {
          console.log(index);
          console.log('delete: ', data);
          console.log('delete: ', uid);
        });
    });
  }

  // deleteEmail() {
  //   console.log('--Comenzamos--' + this.correos.length);

  //   if (this.numerofinal > this.correos.length) {
  //     this.numerofinal = this.correos.length;
  //   }
  //   console.log('NUMERO inicial ' + this.numeroinicial);
  //   console.log('NUMERO FINAL ' + this.numerofinal);
  //   for (let index = this.numeroinicial; index < this.numerofinal; index++) {
  //     const correo = this.correos[index];
  //     let contador = index + 1;
  //     // console.log(contador + '. ' + correo);
  //     // this.eliminarUsuario(correo)
  //     //   .then((res) => {
  //     //     console.log('eliminado', res);
  //     //     console.log(contador);
  //     //   })
  //     //   .catch(() => {
  //     //     console.log('ya estaba eliminado');
  //     //     console.log(contador);
  //     //   });
  //   }
  //   console.log('--Terminamos--');
  // }

  // async eliminarUsuario(email) {
  //   console.log('borrando: ' + email);
  //   try {
  //     return await this.http
  //       .get<any>(
  //         'https://us-central1-the-campus-prod.cloudfunctions.net/delUserEmail',
  //         { params: new HttpParams().set('email', email) }
  //       )
  //       .subscribe((element) => {
  //         console.log('me devuelven esto', element);
  //       });
  //   } catch (error) {console.log("Error al borrar: " + email)}
  // }

  buscarCursos() {
    this.colegiosService
      .getColegioByIdNow('qKQCtDaQ5GRP8wsJVVsi')
      .subscribe((cole: any) => {
        // console.log(cole)
        this.cursos = cole.data().cursos;
        console.log(this.cursos.length);
        // this.buscarClases();
        this.verCursos();
        // this.eliminarCursos();
      });
  }

  eliminarCursos() {
    for (let index = 0; index < this.cursos.length; index++) {
      const curso = this.cursos[index];
      // console.log('ID curso: ' + curso);
      // this.eliminarCurso(curso);
    }
  }

  verCursos() {
    for (let index = 0; index < this.cursos.length; index++) {
      const curso = this.cursos[index];
      // console.log('ID curso: ' + curso);
    }
  }

  buscarClases() {
    for (let index = 0; index < this.cursos.length; index++) {
      const curso = this.cursos[index];
      this.cursosService.getCursosById(curso).subscribe((cursodato: any) => {
        this.clases = cursodato.payload.data().clases;
        this.verClases(this.cursos[index]);
      });
    }

    // this.cursosService.getCursosById("0CuUbDmBjWs1cUwGQBSE").subscribe((cursodato: any) => {
    //   this.clases = cursodato.payload.data().clases;
    //   this.verClases("0CuUbDmBjWs1cUwGQBSE");
    // });

    // this.eliminarCurso("0CuUbDmBjWs1cUwGQBSE");
  }
  verClases(curso) {
    console.log('clases totales: ' + this.clases.length);
    console.log('CURSO------->' + curso);
    for (let index = 0; index < this.clases.length; index++) {
      try {
        const clase = this.clases[index];
        let contador = index + 1;
        console.log(contador + '. ' + clase);
        // this.eliminarClase(clase)
      } catch (error) {
        console.error('---- ¡¡¡ Hubo fallo !!! ----' + error);
      }
    }
  }
  // pruebaEliminarClase(){
  //   this.eliminarClase("dlasjdlaskiasidua98sc12uu")
  // }

  eliminarClase(clase) {
    this.clasesService
      .DeleteClass(clase)
      .then(() => {
        console.log('ELIMINADA LA CLASE: ' + clase);
      })
      .catch((error) => {
        console.error(
          '---- ¡¡¡ Hubo fallo al borrar la clase !!! ----' + error
        );
      });
  }

  eliminarCurso(curso) {
    this.cursosService
      .deleteCurso(curso)
      .then(() => {
        console.log('ELIMINADO EL CURSO: ' + curso);
      })
      .catch((error) => {
        console.error(
          '---- ¡¡¡ Hubo fallo al borrar el curso !!! ----' + error
        );
      });
  }

  estanUsuariosCodigo() {
    for (let index = 0; index < this.codigos.length; index++) {
      const codigo = this.codigos[index];
      this.usuariosService.getUserCodigo(codigo).subscribe((dato) => {
        // dato.docs[0].exists
        if (dato.size == 0) {
          console.log(index + ' ' + codigo);
          this.codigosNoEstan.push(codigo);
        } else {
        }
      });
    }
  }

  cambiarEmailYUsuarioBBDD() {
    let emailsAnt = [];

    let emailsNuevo = [];

    for (let index = 0; index < emailsAnt.length; index++) {
      const emailAntiguo = emailsAnt[index];
      const emailNuevo = emailsNuevo[index];
      this.usuariosService
        .getUserByEmail(emailAntiguo)
        .subscribe((usuarios: any) => {
          if (usuarios.docs.length > 0) {
            let datos = usuarios.docs[0].data();
            let id = usuarios.docs[0].id;
            datos.email = emailNuevo;
            datos.usuario = emailNuevo;
            this.usuariosService
              .editUser(datos, id)
              .then()
              .catch((error) => {
                console.log('Error--- con ' + emailAntiguo, error);
              });
          }
        });
    }
  }

  cogerUids() {
    for (let index = 0; index < this.codigos.length; index++) {
      const codigo = this.codigos[index];
      this.usuariosService.getUserCodigo(codigo).subscribe((dato: any) => {
        // dato.docs[0].exists
        if (dato.empty) {
          console.log(index + ' ' + codigo);
          this.codigosNoEstan.push(codigo);
        } else {
          console.log('usuario', dato.docs[0].data().usuario);
          var valor = {
            uid: dato.docs[0].data().uid,
            usuario: dato.docs[0].data().usuario,
          };
          console.log('valor', valor);
          this.usuariosUid.push(valor);
        }
      });
    }
  }

  descargarUid() {
    this.excelService.exportAsExcelFile(this.usuariosUid, 'user_uids');
  }

  crearLicencias() {
    for (let index = 0; index < this.licencias.length; index++) {
      const element = this.licencias[index];
      var licen = {
        codigo: element,
        colegio: 'GicCEClIcYiFhACkwAll',
        usado: false,
      };
      this.licenciasService.createdLicencia(licen).then((da) => {
        console.log('licencia', licen);
      });
    }
  }

  loginInSAMLcharterschoolit() {
    const provider = new auth.SAMLAuthProvider('saml.charterschoolit');
    this.authenticationService
      .loginWithSAML(provider)
      .then((userCredential) => {
        // console.log("Login correcto" , userCredential);
        console.log(userCredential.user.uid);

        var samlProfile = userCredential.additionalUserInfo.profile;
        var text = JSON.stringify(samlProfile);

        this.usuariosService
          .getUserById(userCredential.user.uid)
          .subscribe((ddata) => {
            if (ddata[0]) {
              var userdata: any = ddata[0].payload.doc.data();
              this.usuariosService.setUserinfo(ddata[0]);

              if (
                userdata.rol === 'estudiante' ||
                userdata.rol === 'profesor' ||
                userdata.rol === 'director' ||
                userdata.rol === 'supervisor'
              ) {
                this.router.navigate(['dashboard']);
              } else if (userdata.rol === 'admin') {
                this.router.navigate(['admin']);
              }
            } else {
              console.log('no hay usuario');
            }
          });

        //userCredential.user.getIdToken()
      })
      .catch((error) => {
        console.log('Hubo este error: ' + error);
      });

    // volver a autenticar a un usuario con reauthenticateWithRedirect() y reauthenticateWithPopup() , que se puede usar para recuperar credenciales nuevas para operaciones confidenciales que requieren un inicio de sesión reciente.
  }
}
