<div class="cuerpo">
  <div class="botones">
    <!-- <button class="boton1" (click)="recogerDatosAlumnos()">EXCEL</button> -->
<!-- 
  <button class="boton1" (click)="getUsuariosPorLista()">Emails</button> -->
  
    <!-- <input
    type="file"
    style="display: inline-block"
    (change)="fileChangeEvent($event)"
    placeholder="Upload file"
    accept=".xlsx"
    /> -->
    <!-- <button class="boton1" (click)="guardar()">SAVE</button>
    <button class="boton1" (click)="comprobar()">COMPROBAR</button>
    <button class="boton1" (click)="verViejos()">ver viejos</button>
    <div  *ngIf="mostrar">
      <div *ngFor="let usuario of usuariosAntiguos; let i = index">
          <p>{{ usuario }}</p>
      </div>
    </div> -->


    <!-- <button class="boton1" (click)="borrarSalasPexip()">BORRAR SALAS PEXIP</button> -->
    <!-- <button class="boton1" (click)="deleteUsers()">DELETE</button> -->
    <!-- <button class="boton1" (click)="borrarPorCodigo()">DELETE</button> -->
    <!-- <button class="boton2" (click)="descargarUid()">UIDS</button> -->
    <!-- <button class="boton2" (click)="descargarUsuariosColegia()">Usuarios</button> -->
    <!-- <div class="usuario">
      <p>NUMERO</p>
      <p>USUARIO</p>
      <p>NOMBRE</p>
      <p>UID</p>
    </div>
    <div class="usuarios">
      <div *ngFor="let usuario of usuarios; let i = index">
        <div class="usuario">
          <p>{{i + 1}}</p>
          <p>{{ usuario.usuario }}</p>
          <p>{{ usuario.nombres }} {{ usuario.apellidos }}</p>
          <p>{{ usuario.uid }}</p>
        </div>
      </div>
    </div>
    <button class="boton1" (click)="deleteUsers()">DELETE</button> -->
    <!-- <div class="tam">

      tamaño = {{correos.length}}
      <p>INICIAL:{{numeroinicial}}</p>
      <p>FINAL :{{numerofinal}}</p>   
    </div>
    
    <input type="number" name="inicio" id="" [(ngModel)]="numeroinicial">
    <input type="number" name="FINAL" id="" [(ngModel)]="numerofinal"> -->
    <!-- <button class="boton1" (click)="deleteEmail()">
      BORRAR USUARIOS
    </button> -->
    <!-- <button class="boton2" (click)="eliminarCursos()">ELIMINAR CURSOS</button> -->
    <!-- <button class="boton3" (click)="pruebaEliminarClase()">PRUEBA</button> -->

    <!-- <button class="boton3" (click)="estanUsuariosCodigo()">USUARIOS NO ESTAN</button>
    <div *ngFor="let codi of codigosNoEstan; let index = i">
      {{index}} - {{codi}}
    </div> -->

    <!-- <button class="boton3" (click)="crearLicencias()">Crear Licencias</button> -->
    <!-- <div>
      <button (click)="loginInSAMLcharterschoolit()" > Login SAML </button>
    </div> -->
  </div>
</div>
